const config = {
  apiGateway: {
    REGION: 'us-east-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://norn29txs3.execute-api.us-east-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://vodkkoulr2.execute-api.us-east-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://gfj93m4610.execute-api.us-east-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.11.2',
    HOSTNAME: 'https://locations.staging.vale.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.vale.forwoodsafety.com',
    WEBSOCKET: 'wss://jiap0cb7vj.execute-api.us-east-2.amazonaws.com/staging'
  },
};

export default config;